import React from "react";
import {
  ThemeProvider,
  responsiveFontSizes,
  createMuiTheme,
} from "@material-ui/core/styles";
import Helmet from "react-helmet";
import fontawesome from "@fortawesome/fontawesome";

import theme from "../theme";
import socialImage from "../assets/images/social.jpg";

const MERGED_THEME = responsiveFontSizes(createMuiTheme(theme));

// console.log(MERGED_THEME)

fontawesome.config = { autoAddCss: false };

const Root = ({ children }) => (
  <ThemeProvider theme={MERGED_THEME}>
    <Helmet>
      <html lang="en" />
      <title>
        Karl Castillo | Accessible Designer and Developer | Karlworks
      </title>
      <meta
        name="description"
        content="The Designer and Developer from Vancouver that focuses on creating accessible experiences."
      />
      <meta name="robots" content="index,follow" />
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap"
        rel="preconnect"
      />

      <meta property="og:url" content="https://karlworks.com" />
      <meta property="og:name" content="Karlworks" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Karl Castillo | Accessible Designer and Developer"
      />
      <meta
        property="og:description"
        content="Creating Websites for an Accessible Future."
      />
      <meta property="og:image" content={socialImage} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@koralarts" />
      <meta
        name="twitter:title"
        content="Karl Castillo | Accessible Designer and Developer"
      />
      <meta
        name="twitter:description"
        content="Creating Websites for an Accessible Future."
      />
      <meta name="twitter:creator" content="@koralarts" />
      <meta name="twitter:image" content={socialImage} />

      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-45202235-1"
        rel="preconnect"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-45202235-1');`}
      </script>
    </Helmet>
    {children}
  </ThemeProvider>
);

export default Root;
