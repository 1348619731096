import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import karlworksLogo from '../assets/images/logo.svg'
import SocialIcons from './common/socialIcons'
import Section from './common/section'

const useClasses = makeStyles(({ spacing }) => ({
  root: {
    display: 'grid',
    gridRowGap: spacing(1),
    gridTemplateColumns: '1fr auto',
    alignItems: 'center'
  },
  logo: {
    flex: 1,
    textAlign: 'left'
  }
}))

const Footer = () => {
  const _classes = useClasses()

  return (
    <Section component='footer' color='secondary' className={_classes.root}>
      <img className={_classes.logo} src={karlworksLogo} height='50' alt='Karlworks Logo' />
      <SocialIcons />
    </Section>
  )
}

export default Footer
