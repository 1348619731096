import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useClasses = makeStyles(({ palette }) => ({
  primary: {
    backgroundColor: palette.primary.main,
    color: palette.getContrastText(palette.primary.main)
  },
  secondary: {
    backgroundColor: '#333333',
    color: palette.getContrastText('#333333')
  }
}))

const Section = ({ children, color, className, ...rest }) => {
  const _classes = useClasses()

  return (
    <Box
      component='section'
      paddingX={{ xs: 1, md: 10 }}
      paddingY={{ xs: 7, md: 10 }}
      {...rest}
      className={clsx(
        { [_classes[color]]: color },
        className
      )}
    >
      {children}
    </Box>
  )
}


export default Section
