import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn, faInstagram, faDev, faStackOverflow } from '@fortawesome/free-brands-svg-icons'

import IconButton from './iconButton'

const ICONS = ['mail', 'dev', 'linkedin', 'instagram', 'stackoverflow']

const useClasses = makeStyles(({ spacing }) => ({
  nav: {
    display: 'grid',
    justifyItems: 'flex-end',
    gridColumnGap: spacing(1),
    gridTemplateColumns: ({ icons }) => `repeat(${icons.length}, 1fr)`,
    gridTemplateRows: '1fr'
  }
}))

const SocialIcons = ({ icons = ICONS }) => {
  const _classes = useClasses({ icons })
  return (
    <nav className={_classes.nav}>
      {icons.includes('mail') && <IconButton
        icon={faEnvelope}
        href='mailto:karl@karlworks.com'
        aria-label='Send me an email at karl@karlworks.com'
        title='Send me an email at karl@karlworks.com'
        color='primary'
        target='_blank'
        rel='noopener noreferrer'
        enableShadow
      />}
      {icons.includes('dev') && <IconButton
        icon={faDev}
        aria-label="Checkout Karl Castillo's Dev"
        title="Checkout Karl Castillo's Dev.to"
        href='https://dev.to/koralarts'
        color='primary'
        target='_blank'
        rel='noopener noreferrer'
        enableShadow
      />}
      {icons.includes('linkedin') && <IconButton
        icon={faLinkedinIn}
        aria-label="Checkout Karl Castillo's LinkedIn"
        title="Checkout Karl Castillo's LinkedIn"
        href='https://linkedin.com/in/khcastillo'
        color='primary'
        target='_blank'
        rel='noopener noreferrer'
        enableShadow
      />}
      {icons.includes('stackoverflow') && <IconButton
        icon={faStackOverflow}
        aria-label="Checkout Karl Castillo's Stackoverflow"
        title="Checkout Karl Castillo's Stackoverflow"
        href='https://stackoverflow.com/users/602086/koralarts'
        color='primary'
        target='_blank'
        rel='noopener noreferrer'
        enableShadow
      />}
      {icons.includes('instagram') && <IconButton
        icon={faInstagram}
        aria-label="Checkout Karl Castillo's Instagram"
        title="Checkout Karl Castillo's Instagram"
        href='https://instagram.com/koralarts'
        color='primary'
        target='_blank'
        rel='noopener noreferrer'
        enableShadow
      />}
    </nav>
  )
}

export default SocialIcons
