module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Karlworks Media","short_name":"Karlworks","start_url":"https://karlworks.com","background_color":"#F78F20","theme_color":"#F78F20","display":"standalone","icon":"/Users/karl/Documents/karlworks/karlworks-web-new/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb3454fb8134b3449f0d3c6e5042b902"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
