import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import karlworksLogo from '../assets/images/logo.svg'
import SocialIcons from './common/socialIcons'
import Section from './common/section'

const useClasses = makeStyles(({ spacing, navigation }) => ({
  root: {
    height: navigation.height,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'grid',
    gridRowGap: spacing(1),
    gridTemplateColumns: '1fr auto',
    alignItems: 'center'
  },
  logo: {
    flex: 1,
    textAlign: 'left'
  }
}))

const Header = () => {
  const _classes = useClasses()

  return (
    <AppBar color='transparent' elevation={0} position='static' component='header'>
      <Toolbar component={Section} variant='dense' className={_classes.root}>
        <img className={_classes.logo} src={karlworksLogo} height='50' alt='Karlworks Logo' />
        <SocialIcons />
      </Toolbar>
    </AppBar>
  )
}

export default Header
