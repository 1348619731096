module.exports = {
  typography: {
    contrastThreshold: 5,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 10,
    htmlFontSize: 10,
    h1: {
      fontSize: "4rem",
    },
    h2: {
      fontSize: "3rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.75rem",
    },
    body1: {
      fontSize: "1.25rem",
    },
    body2: {
      fontSize: "1rem",
    },
    caption: {
      fontSize: "1.25em",
      fontWeight: 800,
    },
  },
  navigation: {
    height: "75px",
  },
  palette: {
    primary: {
      dark: "#DC7508",
      main: "#F78F20",
    },
    text: {
      tertiary: "#FFFFFF",
    },
    background: {
      primary: "#F78F20",
      secondary: "#333333",
    },
  },
};
