import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

const useClasses = makeStyles(({ palette }) => ({
  '@keyframes caretBounce': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.25)' },
    '100%': { transform: 'scale(1)' }
  },
  root: {
    color: palette.common.white,
    background: 'rgba(0, 0, 0, 0.1)',
    width: '50px',
    height: '50px',
    borderRadius: '100%',
    '&:active svg ': { filter: 'none' },
    '&:hover': {
      color: palette.primary.main,
      '& > svg': { animation: '1s linear infinite running $caretBounce'  }
    }
  },
  primary: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark,
      color: palette.common.white
    }
  },
  icon: {
    filter: ({ enableShadow }) => enableShadow
      ? 'drop-shadow(1px 1px 1px black)'
      : 'none'
  },
  focusVisible: {
    outline: `5px solid ${palette.primary.main}`,
    backgroundColor: palette.common.black,
    color: palette.common.white
}
}))

const IconButton = ({ icon, className, color, size='lg', enableShadow=false, ...rest }) => {
  const _classes = useClasses({ enableShadow })

  return (
    <ButtonBase
      classes={{
        root: clsx(
          _classes.root,
          { [_classes[color]]: color },
          className
        ),
        focusVisible: _classes.focusVisible
      }}
      {...rest}
    >
      <FontAwesomeIcon icon={icon} size={size} className={_classes.icon} />
    </ButtonBase>
  )
}

export default IconButton
